import React from 'react';
import { useTranslation } from "react-i18next"

import ProductTemplate from '../../templates/productTemplate';

const ProductList = () => {
  const { t } = useTranslation();
  return (
  <>
    <li>{t("home.program2.productListItem1")}</li>
    <li>{t("home.program2.productListItem2")}</li>
    <li>{t("home.program2.productListItem3")}</li>
  </>
  )
}

const Product3 = () => {
  const { t } = useTranslation();

  return (
  <ProductTemplate
    productId="3"
    title={t("home.program2.program3title")}
    productList={ProductList}
  />
  )
} 

export default Product3
